import React, { useEffect, useState, useRef } from "react";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Image from "../../component/image";
import Text from "../Text";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useAuthentication } from "../../context/authContext";
import { getData, getDataTemp } from "../../utils/api";
import {
  PINNTAG_BUSINESS_PROFILE,
  PINNTAG_USER,
} from "../../config/routes/RoleProtectedRoute";
import { removeItem } from "../../utils/localStorage";
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import AddIcon from '@mui/icons-material/Add';
import NotificIcon from '../../assets/icons/notification.svg'
import { enqueueSnackbar } from "notistack";
import { formatErrorMessage } from "../../utils/formatErrorMessage";
import cn from "classnames";
import useWindowWidth from "../../hooks/useWindowWidth";
import PrimaryModal from "../Modal/PrimaryModal";
import CloseIcon from '@mui/icons-material/Close';
import Notifications from "../../page/notifications";

const Header = () => {

  const location = useLocation();
  const { user, businessUser, setBusinessUser, setUser } = useAuthentication();

  const [addBussiness, setaddBussiness] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const dropdownRef = useRef(null);
  const windowWidth = useWindowWidth();

  const fetchAllBusinessProfiles = async () => {
    setLoading(true);
    const res = await getDataTemp("business-profile/all");
    if (res.data) {
      setData(res.data?.businessProfiles);
    } else {
      console.log(res, "Error while fetching business profiles");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllBusinessProfiles();
  }, []);

  useEffect(() => {
    setaddBussiness(false);
  }, [location])



  const switchToUserProfile = async () => {
    const res = await getData("auth/switch/profile");
    if (res?.data) {
      setUser(res.data);
      setBusinessUser();
      removeItem(PINNTAG_BUSINESS_PROFILE);
      localStorage.setItem(PINNTAG_USER, JSON.stringify(res.data));
      window.location.href = "/dashboard/business-details";
    } else {
      console.error(res.error, "error while switching profile");
    }
  };

  const selectBusinessHandler = async (e, id) => {
    setLoading(true);
    const res = await getDataTemp(`business-profile/switch/${id}`);
    if (res.data) {
      console.log(res);
      setBusinessUser(res.data);
      localStorage.setItem(PINNTAG_BUSINESS_PROFILE, JSON.stringify(res.data));
      businessUser ? window.location.reload() : window.location.href = "/dashboard/content";
      // window.location.href = "/dashboard";
      // window.location.reload();
    } else {
      enqueueSnackbar(
        res.error?.message
          ? formatErrorMessage(res.error?.message)
          : "Something went wrong",
        {
          variant: "error",
        }
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setaddBussiness(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleCloseModal = () => setOpenModal(false);

  console.log(user)
  return (
    <>
      <div className={cn("bg-white fixed top-0 z-30 shadow-lg justify-between flex w-full h-[72px]", windowWidth > 767 ? "items-center pr-4 py-3" : "px-6 py-3 sm:px-6")} >
        {/* company details */}
        {/* <div className={`hidden md:flex  flex-col sm:ml-[190px] px-6 transition-all bg-white 
        ${addBussiness ? `add-buss-menu  gap-4 ${( data && data.length >= 5) && "max-h-[300px] overflow-y-scroll"}` : null}`}>
          {
            businessUser !== undefined ?
              <div className="flex space-x-2 ">
                <Image
                  src={businessUser?.businessProfile.profilePhoto}
                  alt="mainlogo"
                  className="h-[44px] w-[44px] sm:h-[44px] sm:w-[44px]"
                />
                <div className="hidden sm:flex flex-col justify-center pe-2 border-r border-[#DCE4E8]">
                  <Text className="text-caption font-ubuntu text-[#74746E]">My business</Text>
                  <Text className="text-h2 font-ubuntu font-medium">{businessUser?.businessProfile.name}</Text>
                </div>
                <div onClick={(e) => setaddBussiness(!addBussiness)} className="business-drop">
                  <ArrowDropDownRoundedIcon
                    className="text-[#6C7278] w-6 h-6 cursor-pointer"
                  />
                </div>
              </div>
              :
              (data && data.length > 0) ?
                <div className="flex space-x-2 ">
                  <Image
                    src={data[0].profilePhoto}
                    alt="mainlogo"
                    className="h-[44px] w-[44px] sm:h-[44px] sm:w-[44px]"
                  />
                  <div className="hidden sm:flex flex-col justify-center pe-2 border-r border-[#DCE4E8]">
                    <Text className="text-caption font-ubuntu text-[#74746E]">My business</Text>
                    <Text className="text-h2 font-ubuntu font-medium">{data[0].name}</Text>
                  </div>
                  <div onClick={(e) => setaddBussiness(!addBussiness)} className="business-drop">
                    <ArrowDropDownRoundedIcon
                      className="text-[#6C7278] w-6 h-6 cursor-pointer"
                    />
                  </div>
                </div> :

                <div className="flex item-center gap-3 ms-0">
                  <div className="icon-part w-[44px] h-[44px] bg-[#F5F5F3] flex items-center justify-center rounded-md">
                    <AddIcon className="w-[24] h-[24px] text-[#74746E]" />
                  </div>
                  <Link to="/dashboard/business-details">
                    <div className="flex items-center">
                      <Text className="text-h2 font-ubuntu font-medium text-[#74746E]">Add Business</Text>
                    </div>
                  </Link>
                </div>
          }


          {
            addBussiness ?
              <>

                {
                  data && data.map((item, index) => {
                    if (businessUser && item._id === businessUser?.businessProfile._id) {
                      return null;
                    }

                    return (
                      <div key={index} className="flex item-center gap-3 ms-0 mb-2.5 cursor-pointer"
                        onClick={(event) =>
                          selectBusinessHandler(event, item._id)
                        }>
                        <div className="icon-part w-[44px] h-[44px] flex items-center justify-center rounded-md">
                          <Image
                            src={item.profilePhoto}
                            alt="mainlogo"
                            className="h-[44px] w-[44px] sm:h-[44px] sm:w-[44px]"
                          />
                        </div>
                        <div className="flex items-center">
                          <Text className="text-h2 font-ubuntu font-medium text-[#74746E]">{item.name}</Text>
                        </div>
                      </div>
                    );
                  })
                }


                <div className="flex item-center gap-3 ms-0 mb-2.5">
                  <div className="icon-part w-[44px] h-[44px] bg-[#F5F5F3] flex items-center justify-center rounded-md">
                    <AddIcon className="w-[24] h-[24px] text-[#74746E]" />
                  </div>
                  <Link to="/dashboard/business-details">
                    <div className="flex items-center">
                      <Text className="text-h2 font-ubuntu font-medium text-[#74746E]">Add Business</Text>
                    </div>
                  </Link>
                </div>

              </>
              : null
          }

        </div> */}
        <div
          ref={dropdownRef}
          className={`flex-col items-center justify-center transition-all md:ml-[220px] md:w-[400px] bg-white px-1.5 ${addBussiness ? `add-buss-menu profle-show-menu gap-4 ${(data && data.length >= 5) && "overflow-y-scroll overflow-x-hidden break-words whitespace-normal"}` : "profle-hide-menu"}`}
        >
          {businessUser !== undefined ? (
            <div onClick={(e) => setaddBussiness(!addBussiness)} className="flex gap-1.5 nothere">
              <Image
                src={businessUser?.businessProfile.profilePhoto}
                alt="mainlogo"
                className="h-[44px] w-[44px] sm:h-[44px] sm:w-[44px]"
              />
              <div className="w-full flex flex-col justify-center pe-1 border-r border-[#DCE4E8] ">
                <Text className="text-caption font-ubuntu text-[#74746E]">Switch Profile</Text>
                <Text className={`text-h2 font-ubuntu font-medium text-ellipsis overflow-hidden ${!addBussiness ? 'line-clamp-1' : null} `}>{businessUser?.businessProfile.name}</Text>
              </div>
              <div onClick={(e) => setaddBussiness(!addBussiness)} className="business-drop">
                <ArrowDropDownRoundedIcon style={{ width: '32px', height: '32px' }} className="text-[#6C7278] cursor-pointer" />
              </div>
            </div>
          ) : data && data.length > 0 ? (
            <div onClick={() => setaddBussiness(!addBussiness)} className="flex gap-1.5 here">
              <Image
                src={user?.user?.profilePhoto}
                alt="mainlogo"
                className="h-[44px] w-[44px] sm:h-[44px] sm:w-[44px]"
              />
              <div className={cn("sm:flex flex-col justify-center pe-1 border-r border-[#DCE4E8]", windowWidth > 767 ? "w-[400px]" : "w-full")}>
                <Text className="text-caption font-ubuntu text-[#74746E]">Switch Profile</Text>
                <Text className={`whitespace-nowrap text-h2 font-ubuntu font-medium text-ellipsis overflow-hidden md:w-[120px] ${!addBussiness ? 'line-clamp-1' : null}`}>{user?.user?.name}</Text>
              </div>
              <div onClick={(e) => setaddBussiness(!addBussiness)} className="business-drop">
                <ArrowDropDownRoundedIcon style={{ width: '32px', height: '32px' }} className="text-[#6C7278] cursor-pointer" />
              </div>
            </div>
          ) : (
            <div className="flex item-center gap-3 ms-0">
              <div className="icon-part w-[44px] h-[44px] bg-[#F5F5F3] flex items-center justify-center rounded-md">
                <AddIcon className="w-[24] h-[24px] text-[#74746E]" />
              </div>
              <Link to="/dashboard/business-details">
                <div className="flex items-center">
                  <Text className="text-h2 font-ubuntu font-medium text-[#74746E]">Add Business</Text>
                </div>
              </Link>
            </div>
          )}

          {addBussiness ? (
            <>
              {user && businessUser !== undefined && (
                <div>
                  <p
                    onClick={() => {switchToUserProfile(); setaddBussiness(!addBussiness)}}
                    className="flex item-center gap-3 ms-0 mt-4 mb-4 cursor-pointer"
                  >
                    <div className="icon-part w-[44px] h-[44px] flex items-center justify-center rounded-md">
                      <Image
                        src={user?.user?.profilePhoto}
                        alt="profile icon"
                        className="h-[44px] w-[44px] sm:h-[44px] sm:w-[44px]"
                      />
                    </div>
                    <div className="flex items-center">
                      <Text className="text-h2 font-ubuntu font-medium text-[#74746E] text-ellipsis overflow-hidden">
                        {user?.user?.name}
                      </Text>
                    </div>
                  </p>
                </div>
              )}
              {data &&
                data
                .filter((item) => !(businessUser && item._id === businessUser?.businessProfile?._id))
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item, index) => (
                    <div
                      key={index}
                      className={`${item.isDeleted ? "hidden" : "flex items-center gap-3 ms-0 my-2.5 cursor-pointer"}`}
                      onClick={(event) => selectBusinessHandler(event, item._id)}
                    >
                      <div className="icon-part w-[44px] h-[44px] flex items-center justify-center rounded-md flex-shrink-0">
                        <Image
                          src={item.profilePhoto}
                          alt="mainlogo"
                          className="h-[44px] w-[44px] sm:h-[44px] sm:w-[44px]"
                        />
                      </div>
                      <div className="flex items-center flex-grow overflow-hidden">
                        <Text className="text-h2 font-ubuntu font-medium text-[#74746E] break-words whitespace-normal overflow-hidden">
                          {item.name}
                        </Text>
                      </div>
                    </div>
                  ))}

              <div className="flex item-center gap-3 ms-0 mb-2.5">
                <div className="icon-part w-[44px] h-[44px] bg-[#F5F5F3] flex items-center justify-center rounded-md">
                  <AddIcon className="w-[24] h-[24px] text-[#74746E]" />
                </div>
                <Link to="/dashboard/business-details">
                  <div className="flex items-center">
                    <Text className="text-h2 font-ubuntu font-medium text-[#74746E]">Add Business</Text>
                  </div>
                </Link>
              </div>
            </>
          ) : null}
        </div>
        {/* user detail */}
        <div className="flex justify-center w-fit h-fit items-center space-x-4 sm:space-x-6 p-2 ms-auto">
          {/* <Link to={'/dashboard/notifications'}>
            <Image
              src={NotificIcon}
              alt="notification icon"
              className="h-[24px] w-[24px] sm:h-[24px] sm:w-[24px]"
            />
          </Link> */}
            <Image
              src={NotificIcon}
              alt="notification icon"
              className="h-[24px] w-[24px] sm:h-[24px] sm:w-[24px]"
              onClick={() => setOpenModal(state => !state)}
            />
        </div>
      </div>
      <PrimaryModal
        open={openModal}
        handleClose={handleCloseModal}
        title="Alert"
        fixed
        modalClass="w-[380px] sm:w-[500px] md:w-[600px] lg:w-[600px] xl:w-[600px]"
        fixedHeight='73vh'
      >
        <div className="mt-[4rem] overflow-y-auto max-h-[70vh] px-4">
          <Notifications />
        </div>
      </PrimaryModal>
    </>

  );
};

export default Header;
