import React from "react";
import { Link } from "react-router-dom";
import GuestRoute from "./GuestRoute";
import BusinessDetailEdit from "../../page/businessDetailEdit";
import SignUp from "../../page/signup";
import DashboardContent from "../../page/dashboard";
import DashboardLayout from "../../common/DashboardLayout";
import Subold from "../../page/subold";
import Socialmedia from "../../page/socialmedia";
import Notifications from "../../page/notifications";
import { elements } from "chart.js";
import componentLoader from "./ComponentLoader";

const Layout = React.lazy(() => componentLoader(() => import("./../../common/layout")));
const BuisnessDetails = React.lazy(() => componentLoader(() => import("../../page/buisnessdetails")));
const Content = React.lazy(() => componentLoader(() => import("../../page/content")));
const ViewEvent = React.lazy(() => componentLoader(() => import("../../page/viewEvent")));
const Imagegallery = React.lazy(() => componentLoader(() => import("../../page/imagegallery")));
const Buisnessuser = React.lazy(() => componentLoader(() => import("../../page/buisnessuser")));
const Login = React.lazy(() => componentLoader(() => import("../../page/login")));
const Location = React.lazy(() => componentLoader(() => import("../../page/location")));
const EditUserDetails = React.lazy(() => componentLoader(() => import("../../page/editUserDetails")));
const RoleProtectedRoute = React.lazy(() => componentLoader(() => import("./RoleProtectedRoute")));

const PaymentHistory = React.lazy(() => componentLoader(() => import("../../page/payment/PaymentHistory")));
const PaymentMethod = React.lazy(() => componentLoader(() => import("../../page/paymentMethod/PaymentMethod")));

export const PATHS = {
  buisnessDetails: "/dashboard/buisness-details",
  content: "/dashboard/content",
  imageGallery: "/dashboard/image-gallery",
  businessUser: "/dashboard/buisness-users",
  login: "/login",
  dashboard: "/dashboard/",
  location: "/location"
};

export const routeslist = [
  {
    path: "/",
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "signup",
        element: <SignUp />,
      },

    ],
  },
  {
    path: "/dashboard",
    element: <RoleProtectedRoute element={<DashboardLayout />} module="user" />,
    children: [
      {
        path: "business-details",
        element: <BuisnessDetails />,
      },
      {
        path: "edit-user",
        element: <EditUserDetails />
      },
      {
        path: "payment-history",
        element: <PaymentHistory />,
      },
      {
        path: "payment-method",
        element: <PaymentMethod />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: <RoleProtectedRoute element={<Layout />} module="user" />,
    children: [
      {
        path: "",
        element: <DashboardContent />,
      },
      {
        path: "edit-business-details",
        element: <BusinessDetailEdit />,
      },
      {
        path: "subold",
        element: <Subold />,
      },
      {
        path: "notifications",
        element: <Notifications />,
      },
      {
        path: "content",
        element: <Content />,
      },
      {
        path:"socialmedia",
        element:<Socialmedia />
      },
      {
        path: "content/:id",
        element: <ViewEvent />,
      },
      {
        path: "image-gallery",
        element: <Imagegallery />,
      },
      {
        path: "business-user",
        element: <Buisnessuser />,
      },
      {
        path: "location",
        element: <Location />,
      },
      {
        path: "payment-history",
        element: <PaymentHistory />,
      },
      {
        path: "payment-method",
        element: <PaymentMethod />,
      },
    ],
  },
  // @fixme new 404 page must be there
  {
    path: "*",
    element: (
      <>
        404, Page not found
        <Link to="/login">Go Home</Link>
      </>
    ),
  },
];
